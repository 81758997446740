import React from 'react'
import { Link } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { AsyncWrapper, TabsList } from '/src/components/common'
import { OrderTable } from '/src/components/commerce'
import { useOrders, useUser } from '/src/hooks'

const UserOrdersDataWrapper = () => {
  const orders = useOrders()
  return <OrderTable orders={orders} />
}

const UserOrdersPage = () => {
  return (
    <Layout hero={<HeroImage title="Orders" />}>
      <Row className="d-flex justify-content-center">
        <Col md={10} lg={10}>
          <Row className="mt-2 mb-4">
            <Col sm={6}>
              <div className="page-title">
                <h1 className="title">My Orders</h1>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                <Link className="button outline" to="/user/logout">
                  Logout
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" sm={12}>
              <TabsList items={[
                  {
                    label: 'Account',
                    path: '/user'
                  },
                  {
                    label: 'Orders',
                    path: '/user/orders'
                  },
                  {
                    label: 'Enquiries',
                    path: '/user/enquiries'
                  },
                  {
                    label: 'Favourites',
                    path: '/user/favourites'
                  }
                ]} />
            </Col>
          </Row>
          <AsyncWrapper>
            <UserOrdersDataWrapper />
          </AsyncWrapper>
        </Col>
      </Row>
    </Layout>
  )
}

export default UserOrdersPage

export const Head = () => <title>My Orders | Metalcorp</title>
